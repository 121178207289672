import { Notify } from 'vuetify-message-snackbar'

const state = {
  timeout: 3000
}

const mutations = {
  snackbar (state, active) {
    state.active = active
  },
  // 在右上方显示
  errorright (state, err) {
    let text = err.msg ? err.code + ' ' + err.msg : err
    if (err.code === 3028) {
      text = err.data ? err.code + ' ' + err.data : err
    }
    Notify.topRight().closeButtonContent('关闭').timeout(4000).error(text)
  },
  // 在正上方显示
  error (state, err) {
    let text = err.msg ? err.code + ' ' + err.msg : err
    if (err.code === 3028) {
      text = err.data ? err.code + ' ' + err.data : err
    }
    Notify.top().closeButtonContent('关闭').timeout(4000).error(text)
  },
  success (state, text) {
    Notify.topRight().closeButtonContent('关闭').timeout(3000).info(text)
  }
}

const actions = {
  error ({ commit }, msg) {
    commit('error', msg)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
