import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'

const state = {
  id: 0, // 授权编号
  name: '', // 授权给谁（公司）
  code: '', // 授权帐号
  expire: '', // 有效期
  avatar: '', // 公司Logo
  introduction: '',
  menu: null,
  roles: []
}

const mutations = {
  SET_MENU: (state, menu) => {
    state.menu = menu
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_CODE: (state, code) => {
    state.code = code
  },
  SET_EXPIRE: (state, expire) => {
    state.expire = expire
  },
  SET_ID: (state, id) => {
    state.id = id
  },
  SET_AVATAR: (state, avatar) => {
    // state.avatar = process.env.VUE_APP_BASE_API + avatar
    state.avatar = avatar
  }
}

const actions = {
  // 来自分享的token
  share ({ commit }, pkg) {
    return new Promise((resolve, reject) => {
      commit('SET_ID', 10000)
      commit('SET_NAME', '演示帐号')
      commit('SET_CODE', 'woohle')
      commit('SET_EXPIRE', '2029-01-01')
      commit('SET_AVATAR', '')
      setToken(pkg.token, pkg.pid)
      resolve()
    })
  },

  login ({ commit }, userInfo) {
    const { username, password, valicode, uuid } = userInfo
    return new Promise((resolve, reject) => {
      login({
        username: username.trim(),
        password: password,
        uuid: uuid,
        valicode: valicode
      }).then((res) => {
        commit('SET_ID', res.info.id)
        commit('SET_NAME', res.info.name)
        commit('SET_CODE', res.info.code)
        commit('SET_EXPIRE', res.info.expire)
        commit('SET_AVATAR', res.info.avatar)
        if (res.info.menu) {
          commit('SET_MENU', res.info.menu)
        }
        setToken(res.token, res.pid)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // 获取用户信息
  getInfo ({ commit, state }) {
    return new Promise((resolve, reject) => {
      const { token } = getToken()
      if (token) {
        getInfo().then(res => {
          commit('SET_ID', res.id)
          commit('SET_NAME', res.name)
          commit('SET_CODE', res.code)
          commit('SET_EXPIRE', res.expire)
          commit('SET_AVATAR', res.avatar)
          if (res.menu) {
            commit('SET_MENU', res.menu)
          }
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      }
    })
  },

  // user logout
  logout ({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      const { token } = getToken()
      logout(token).then(() => {
        removeToken()
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken ({ commit }) {
    return new Promise(resolve => {
      removeToken()
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
