import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { VSnackbar, VBtn, VIcon } from 'vuetify/lib'
import VueMessageSnackbar from 'vuetify-message-snackbar'
import 'typeface-roboto/index.css' // 引入本地的Roboto字体资源
import '@mdi/font/css/materialdesignicons.css' // 引入本地的Material Design Icons资源\

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon
  }
})
const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi' // 设置使用本地的icon资源
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        dragback: '#f6f8fa', // 拖动组件背景色
        dragback2: '#e0e0e0', // 明显的分隔线
        primary: '#00b259',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00'
      },
      dark: {
        dragback: '#363738',
        dragback2: '#363738',
        primary: '#00b259',
        secondary: '#424242',
        accent: '#FF4081',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00'
      }
    },
    dark: false
  }
})

Vue.use(VueMessageSnackbar, {
  vuetifyInstance: vuetify,
  appendTo: '#whapp'
})

export default vuetify
