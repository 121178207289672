import ReconnectingWebSocket from 'reconnecting-websocket'
import { getToken } from '@/utils/auth'
import emitter from '@/utils/emitter'

const state = {
  websocket: null,
  websocketState: 'disconnected'
}

const mutations = {
  websocketCreate (state, websocket) {
    state.websocket = websocket
  },
  websocketFailure (state) {
    state.websocket = null
  },
  websocketOnOpen (state, rws) {
    state.websocketState = 'connected'
    emitter.emit('on-connected')
  },
  websocketOnClose (state, rws) {
    state.websocketState = 'disconnected'
  },
  websocketOnError (state, { rws, err }) {
    state.websocketState = 'disconnected'
  },
  websocketOnMessage (state, { rws, message }) {
    emitter.emit('on-message', message)
  },
  websocketSend (state, { type, data }) {
    if (state.websocket && state.websocketState === 'connected') {
      const msg = {
        type: type,
        payload: data
      }
      state.websocket.send(JSON.stringify(msg))
    } else {
      console.error(' websocketSend Err:', state.websocketState)
    }
  },
  logout (state) {
    if (state.websocket) {
      state.websocket.close()
    }
    state.websocket = null
    state.websocketState = 'disconnected'
  }
}

const actions = {
  logout ({ commit }) {
    commit('logout')
  },
  connect ({ commit, state }, force) {
    if (force) {
      commit('logout')
    } else if (state.websocketState === 'connected') {
      return
    }
    const options = {
      reconnectInterval: 5000,
      connectionTimeout: 5000,
      maxRetries: 100
    }
    const { token, pid } = getToken()
    let uri = `${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.host}${process.env.VUE_APP_BASE_API}/ws/main`
    if (process.env.NODE_ENV === 'development') {
      const dev = new URL(process.env.VUE_APP_BASE_API)
      if (dev) {
        uri = `ws://${dev.host}${dev.pathname}/ws/main`
      }
    }
    const wsobj = `${token}-${pid}`
    const rws = new ReconnectingWebSocket(uri, [wsobj], options)
    rws.onopen = function () {
      console.log('websocketOnOpen')
      commit('websocketOnOpen', rws)
    }
    rws.onclose = function (e) {
      console.log('websocketOnClose')
      commit('websocketOnClose', rws)
    }
    rws.onerror = function (err) {
      console.log('websocketOnError')
      commit('websocketOnError', { rws, err })
    }
    rws.onmessage = json => {
      const message = JSON.parse(json.data)
      commit('websocketOnMessage', { rws, message })
    }
    commit('websocketCreate', rws)
    return new Promise((resolve) => {
      if (rws.readyState !== rws.OPEN) {
        rws.addEventListener('open', (_) => {
          resolve()
        })
      } else {
        resolve()
      }
    })
  },
  send ({ dispatch, commit, state }, data) {
    if (state.websocketState === 'connected') {
      commit('websocketSend', data)
    } else {
      dispatch('alert/error', '未连接服务器', { root: true })
    }
  },
  // 大屏入口
  gateway ({ commit }, data) {
    commit('websocketSend', { type: 'gateway', data: data })
  },
  // ,
  // write ({ commit }, data) {
  //   commit('websocketSend', { type: 'write', data: data })
  // },
  // logger ({ commit }, data) {
  //   commit('websocketSend', { type: 'logger', data: data })
  // },
  // monitor ({ commit }, data) {
  //   commit('websocketSend', { type: 'monitor', data: data })
  // },
  // read ({ commit }, data) {
  //   commit('websocketSend', { type: 'read', data: data })
  // },
  // list ({ commit }) {
  //   commit('websocketSend', { type: 'list' })
  // }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
