import axios from 'axios'
// import store from '@/store'
import { getToken } from '@/utils/auth'

// create an axios instance
// 下载图片或文件方式
const service = axios.create({
  responseType: 'blob',
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    const { token } = getToken()
    if (token) {
      config.headers['whtoken'] = token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    // return response
    return window.URL.createObjectURL(new Blob([response.data]))
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
