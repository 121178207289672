/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/. */
import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import forEach from 'lodash/forEach'
// import find from 'lodash/find'
// import utils from '../views/design/utils'
// import { createSettingStorageFromLocalStorage } from '../views/design/LimitedSettingsStorage.js'
import shortid from 'shortid'
// import { itemCompleteTransform } from '../views/design/scheme/WhContainer.js'
// import whMath from '../views/design/whMath'

const modulesFiles = require.context('./modules', true, /\.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

Vue.use(Vuex)

// const myStorage = createSettingStorageFromLocalStorage('store', 100)

// function createCurvePointConverter (item) {
//   const completeTransform = itemCompleteTransform(item)

//   return (point) => {
//     const convertedPoint = utils.clone(point)
//     const p = whMath.transformPoint(completeTransform, point.x, point.y)

//     convertedPoint.x = p.x
//     convertedPoint.y = p.y

//     if (point.t === 'B') {
//       const p1 = whMath.transformPoint(completeTransform, point.x + point.x1, point.y + point.y1)
//       const p2 = whMath.transformPoint(completeTransform, point.x + point.x2, point.y + point.y2)
//       convertedPoint.x1 = p1.x - p.x
//       convertedPoint.y1 = p1.y - p.y
//       convertedPoint.x2 = p2.x - p.x
//       convertedPoint.y2 = p2.y - p.y
//     }
//     return convertedPoint
//   }
// }

function enrichCurvePoint (point) {
  if (point.t === 'B') {
    let length = Math.sqrt(point.x1 * point.x1 + point.y1 * point.y1)
    let vx1 = 1; let vy1 = 0
    if (length > 0.000001) {
      vx1 = point.x1 / length
      vy1 = point.y1 / length
    }
    length = Math.sqrt(point.x2 * point.x2 + point.y2 * point.y2)
    let vx2 = 1; let vy2 = 0
    if (length > 0.000001) {
      vx2 = point.x2 / length
      vy2 = point.y2 / length
    }
    point.vx1 = vx1
    point.vy1 = vy1
    point.vx2 = vx2
    point.vy2 = vy2
  }
}

const store = new Vuex.Store({
  modules,
  getters,
  state: {
    apiClient: null,

    schemeModified: false,

    editorStateName: 'preview',

    curveEditing: {
      // item whose curve is currently edited
      item: null,
      points: []
    },

    // autoRemount: true,
    showPivot: true,

    // stores the state of the history in scheme editing
    history: {
      undoable: false,
      redoable: false
    },

    snap: {
      grid: false, // 辅助线-表格对齐 myStorage.get('snap.grid', false),
      items: true // 辅助线-元素对齐 myStorage.get('snap.items', true)
    },

    itemSurround: {
      padding: 40
    },

    itemControlPoints: [],

    multiSelectBox: null,

    // used to render snapping lines when user drags item and it is snapped to other items
    snappers: {
      horizontal: null,
      vertical: null
    },

    // used for storing information about images that were dropped on svg editor
    imageUpload: {
      images: new Map()
    },

    statusMessage: {
      message: null,
      isError: false
    },

    // Contains global self destructing messages that can be written from any components
    systemMessages: [],

    draw: {
      epsilon: 5
    }
    // animationEditor: {
    //   currentFramePlayer: null,
    //   isRecording: false
    // }
  },
  mutations: {
    SET_API_CLIENT (state, apiClient) {
      state.apiClient = apiClient
    },

    SET_SCHEME_MODIFIED (state, isModified) {
      state.schemeModified = isModified
    },

    // SET_AUTO_REMOUNT (state, autoRemount) {
    //   state.autoRemount = autoRemount
    // },

    SET_SHOW_PIVOT (state, show) {
      state.showPivot = show
    },

    /* Curve Editing */
    // SET_CURVE_EDIT_ITEM (state, { item, points }) {
    //   state.curveEditing.item = item
    //   state.curveEditing.points.length = 0
    //   if (item) {
    //     const pointConverter = createCurvePointConverter(item)
    //     forEach(points, point => {
    //       state.curveEditing.points.push(pointConverter(point))
    //     })
    //   }
    // },
    // UPDATE_CURVE_EDIT_POINT (state, { item, pointId, point }) {
    //   if (pointId < 0 || pointId >= state.curveEditing.points.length) {
    //     return
    //   }

    //   const pointConverter = createCurvePointConverter(item)
    //   const convertedPoint = pointConverter(point)

    //   forEach(convertedPoint, (value, field) => {
    //     state.curveEditing.points[pointId][field] = value
    //     enrichCurvePoint(state.curveEditing.points[pointId])
    //   })
    // },
    TOGGLE_CURVE_EDIT_POINT_SELECTION (state, { pointId, inclusive }) {
      if (pointId < 0 || pointId >= state.curveEditing.points.length) {
        return
      }
      if (inclusive) {
        state.curveEditing.points[pointId].selected = !state.curveEditing.points[pointId].selected
      } else {
        forEach(state.curveEditing.points, (point, pId) => {
          point.selected = pId === pointId
        })
      }
    },
    RESET_CURVE_EDIT_POINT_SELECTION (state) {
      forEach(state.curveEditing.points, point => {
        point.selected = false
      })
    },
    SELECT_CURVE_EDIT_POINT (state, { pointId, inclusive }) {
      if (pointId < 0 || pointId >= state.curveEditing.points.length) {
        return
      }
      if (inclusive) {
        state.curveEditing.points[pointId].selected = true
      } else {
        forEach(state.curveEditing.points, (point, pId) => {
          point.selected = pId === pointId
        })
      }
    },

    /* History */
    SET_HISTORY_UNDOABLE (state, isUndoable) {
      state.history.undoable = isUndoable
    },
    SET_HISTORY_REDOABLE (state, isRedoable) {
      state.history.redoable = isRedoable
    },

    SET_GRID_SNAP (state, enabled) {
      state.snap.grid = enabled
      // myStorage.save('snap.grid', enabled)
    },
    SET_ITEM_SNAP (state, enabled) {
      state.snap.items = enabled
      // myStorage.save('snap.items', enabled)
    },
    SET_EDITOR_STATE_NAME (state, stateName) {
      state.editorStateName = stateName
    },
    SET_ITEM_SURROUND_PADDING (state, padding) {
      state.itemSurround.padding = padding
    },

    /** ************* Item Control Points *****************/
    SET_ITEM_CONTROL_POINTS (state, itemControlPoints) {
      state.itemControlPoints = itemControlPoints
    },
    CLEAR_ITEM_CONTROL_POINTS (state) {
      state.itemControlPoints.length = 0
    },

    SET_MULTI_SELECT_BOX (state, box) {
      state.multiSelectBox = box
    },

    /** ********** Snappers ****************/
    SET_ITEM_SNAPPER (state, snapper) {
      if (snapper.snapperType === 'horizontal') {
        state.snappers.horizontal = snapper
      } else if (snapper.snapperType === 'vertical') {
        state.snappers.vertical = snapper
      }
    },
    CLEAR_ITEM_SNAPPERS (state) {
      state.snappers.horizontal = null
      state.snappers.vertical = null
    },

    UPDATE_IMAGE_UPLOAD_STATUS (state, { imageId, uploading, uploadFailed }) {
      if (uploadFailed) {
        state.statusMessage.message = 'Failed to upload image'
        state.statusMessage.isError = true
      }

      if ((!uploading || uploadFailed) && state.imageUpload.images.has(imageId)) {
        state.imageUpload.images.delete(imageId)
      } else {
        state.imageUpload.images.set(imageId, { uploading, date: new Date() })
      }

      let imagesUploading = 0

      state.imageUpload.images.forEach(image => {
        if (image.uploading) {
          imagesUploading += 1
        }
      })

      if (state.statusMessage.isError && state.statusMessage.message) {
        return
      }

      if (imagesUploading > 0) {
        let suffix = ''
        if (imagesUploading > 1) {
          suffix = 's'
        }
        state.statusMessage.message = `Uploading ${imagesUploading} image${suffix}`
        state.statusMessage.isError = false
      } else {
        state.statusMessage.message = null
        state.statusMessage.isError = false
      }
    },

    RESET_IMAGE_UPLOAD_STATUS (state) {
      const now = new Date()
      const toDeleteIds = []
      state.imageUpload.images.forEach((image, imageId) => {
        if (now.getTime() - image.date.getTime() > 45000) {
          toDeleteIds.push(imageId)
        }
      })

      forEach(toDeleteIds, id => {
        state.imageUpload.images.delete(id)
      })
    },

    CLEAR_STATUS_MESSAGE (state) {
      state.statusMessage.message = null
      state.statusMessage.isError = false
    },

    SET_STATUS_MESSAGE (state, { message, isError }) {
      state.statusMessage.message = message
      state.statusMessage.isError = isError
    },

    ADD_SYSTEM_MESSAGE (state, { message, status, id }) {
      if (id) {
        // checking if there are already messages with the same id
        for (let i = 0; i < state.systemMessages.length; i++) {
          if (state.systemMessages[i].id === id) {
            return
          }
        }
      } else {
        id = shortid.generate()
      }

      state.systemMessages.push({
        id,
        message,
        status
      })

      const timeout = 5000
      const selfDestruct = () => {
        for (let i = 0; i < state.systemMessages.length; i++) {
          if (state.systemMessages[i].id === id) {
            state.systemMessages.splice(i, 1)
            return
          }
        }
      }

      setTimeout(selfDestruct, timeout)
    },

    REMOVE_SYSTEM_MESSAGE (state, id) {
      for (let i = 0; i < state.systemMessages.length; i++) {
        if (state.systemMessages[i].id === id) {
          state.systemMessages.splice(i, 1)
          return
        }
      }
    },

    UPDATE_DRAW_EPSILON (state, epsilon) {
      if (!isNaN(epsilon)) {
        state.draw.epsilon = epsilon
      }
    }

    // START_ANIMATION_EDITOR (state, framePlayer) {
    //   state.animationEditor.currentFramePlayer = framePlayer
    // },

    // SET_ANIMATION_EDITOR_RECORDING (state, isRecording) {
    //   state.animationEditor.isRecording = isRecording
    // }
  },

  actions: {
    setApiClient ({ commit }, apiClient) {
      commit('SET_API_CLIENT', apiClient)
    },

    // markSchemeAsModified ({ commit }) {
    //   commit('SET_SCHEME_MODIFIED', true)
    // },
    // markSchemeAsUnmodified ({ commit }) {
    //   commit('SET_SCHEME_MODIFIED', false)
    // },

    // setCurveEditItem ({ commit }, item) {
    //   const points = []
    //   if (item) {
    //     forEach(item.attrs.points, (point, pointId) => {
    //       const p = utils.clone(point)
    //       p.id = pointId
    //       p.selected = false
    //       enrichCurvePoint(p)
    //       points.push(p)
    //     })
    //   }
    //   commit('SET_CURVE_EDIT_ITEM', { item, points })
    // },
    // updateCurveEditPoint ({ commit }, { item, pointId, point }) {
    //   commit('UPDATE_CURVE_EDIT_POINT', { item, pointId, point })
    // },
    // toggleCurveEditPointSelection ({ commit }, { pointId, inclusive }) {
    //   commit('TOGGLE_CURVE_EDIT_POINT_SELECTION', { pointId, inclusive })
    // },
    // resetCurveEditPointSelection ({ commit }) {
    //   commit('RESET_CURVE_EDIT_POINT_SELECTION')
    // },
    // selectCurveEditPoint ({ commit }, { pointId, inclusive }) {
    //   commit('SELECT_CURVE_EDIT_POINT', { pointId, inclusive })
    // },

    // setHistoryUndoable ({ commit }, isUndoable) {
    //   commit('SET_HISTORY_UNDOABLE', isUndoable)
    // },

    // setHistoryRedoable ({ commit }, isRedoable) {
    //   commit('SET_HISTORY_REDOABLE', isRedoable)
    // },

    // setGridSnap ({ commit }, enabled) {
    //   commit('SET_GRID_SNAP', enabled)
    // },
    // setItemSnap ({ commit }, enabled) {
    //   commit('SET_ITEM_SNAP', enabled)
    // },

    // setEditorStateName ({ commit }, stateName) {
    //   commit('SET_EDITOR_STATE_NAME', stateName)
    // },

    // setItemSurroundPadding ({ commit }, padding) {
    //   commit('SET_ITEM_SURROUND_PADDING', padding)
    // },

    // setItemControlPoints ({ commit }, itemControlPoints) {
    //   const list = []
    //   forEach(itemControlPoints, (ctrlPoint, pointId) => {
    //     list.push({
    //       id: pointId,
    //       point: ctrlPoint
    //     })
    //   })
    //   commit('SET_ITEM_CONTROL_POINTS', list)
    // },

    // clearItemControlPoints ({ commit }) {
    //   commit('CLEAR_ITEM_CONTROL_POINTS')
    // },

    // setMultiSelectBox ({ commit }, box) {
    //   commit('SET_MULTI_SELECT_BOX', box)
    // },

    // setItemSnapper ({ commit }, snapper) {
    //   commit('SET_ITEM_SNAPPER', snapper)
    // },
    // clearItemSnappers ({ commit }) {
    //   commit('CLEAR_ITEM_SNAPPERS')
    // },

    // setAutoRemount ({ commit }, autoRemount) {
    //   commit('SET_AUTO_REMOUNT', autoRemount)
    // },

    // setShowPivot ({ commit }, show) {
    //   commit('SET_SHOW_PIVOT', show)
    // },

    // setShowClickableMarkers ({ commit }, show) {
    //   commit('SET_SHOW_CLICKABLE_MARKERS', show)
    // },

    // updateImageUploadStatus ({ commit }, { imageId, uploading, uploadFailed }) {
    //   commit('UPDATE_IMAGE_UPLOAD_STATUS', { imageId, uploading, uploadFailed })

    //   // triggering an update of all messages in a minute
    //   // this is needed in case image uploading gets somehow stuck
    //   setTimeout(() => {
    //     commit('RESET_IMAGE_UPLOAD_STATUS')
    //   }, 60000)
    // },

    // clearStatusMessage ({ commit }) {
    //   commit('CLEAR_STATUS_MESSAGE')
    // },

    // setErrorStatusMessage ({ commit }, message) {
    //   commit('SET_STATUS_MESSAGE', { message, isError: true })
    // },

    // addSystemMessage ({ commit }, { message, status, id }) {
    //   commit('ADD_SYSTEM_MESSAGE', { message, status, id })
    // },
    // removeSystemMessage ({ commit }, id) {
    //   commit('REMOVE_SYSTEM_MESSAGE', id)
    // },

    // updateDrawEpsilon ({ commit }, epsilon) {
    //   commit('UPDATE_DRAW_EPSILON', epsilon)
    // }

    // startAnimationEditor ({ commit }, framePlayer) {
    //   commit('START_ANIMATION_EDITOR', framePlayer)
    // },

    // setAnimationEditorRecording ({ commit }, isRecording) {
    //   commit('SET_ANIMATION_EDITOR_RECORDING', isRecording)
    // }
  }
})

export default store
