import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { getToken } from '@/utils/auth'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    const { token, pid } = getToken()
    if (token) {
      config.headers['whtoken'] = token
    }
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    
    // 可能是地图json，直接写
    if (res.code == undefined || res.code==null) {
      return res
    }

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 0) {
      // 帐号未登录
      if (res.code === -101 || res.code === -622) {
        store.dispatch('user/resetToken').then(() => {
          router.push('/login')
          // location.reload()
        })
      }
      // 只是将错误往下外丢，并不做提示
      return Promise.reject(res)
    } else {
      // 只要数据部分
      return res.data
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
