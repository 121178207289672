const getters = {
  size: state => state.app.size,
  device: state => state.app.device,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  code: state => state.user.code,
  id: state => state.user.id,
  expire: state => state.user.expire,
  apiClient: state => state.apiClient,
  schemeModified: state => state.schemeModified,
  itemControlPointsList: state => state.itemControlPoints,
  curveEditPoints: state => state.curveEditing.points,
  multiSelectBox: state => state.multiSelectBox,
  horizontalSnapper: state => state.snappers.horizontal,
  verticalSnapper: state => state.snappers.vertical,
  shouldSnapToGrid: state => state.snap.grid,
  shouldSnapToItems: state => state.snap.items,
  showPivot: state => state.showPivot,
  statusMessage: state => state.statusMessage,
  systemMessages: state => state.systemMessages,
  drawEpsilon: state => state.draw.epsilon,
  editorStateName: state => state.editorStateName
}
export default getters
