<template>
  <div class="gmenu">
    <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" permanent>
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img src="@/assets/img/user.png"></v-img>
        </v-list-item-avatar>
        <v-list-item-title>{{ name }}</v-list-item-title>
        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
      <v-divider></v-divider>
      <div class="menu-header">
        <v-list subheader expand class="menu">
          <v-list-group v-for="item in items" :key="item.id" @click="btnClick(item)" v-model="item.active" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:appendIcon>
              <v-icon v-if="item.children">mdi-chevron-down</v-icon>
              <v-icon v-else></v-icon>
            </template>
            <template v-slot:prependIcon>
              <v-icon v-if="item.icon && item.icon.startsWith('mdi')" size="18" left>
                {{ item.icon }}
              </v-icon>
              <img v-else :src="`${host}${item.icon}`" class="xicon" />
            </template>
            <v-list-item :input-value="subItem.id === selectedIndex" color="var(--v-primary-base)"
              v-for="subItem in item.children" :key="subItem.id" @click="btnClick(subItem)">
              <v-list-item-content>
                <v-list-item-title>{{ subItem.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DataNavBar',
  props: {},
  mounted() {
  },
  data() {
    const mini = localStorage.getItem('navbar.mini') === 'true' || false
    return {
      drawer: false,
      host: process.env.VUE_APP_BASE_API,
      width: 206,
      mini: mini,
      selectedIndex: 0,
      items: []
    }
  },
  computed: {
    ...mapState({
      name: (state) => state.user.name,
      code: (state) => state.user.code,
      id: (state) => state.user.id,
      avatar: (state) => state.user.avatar,
      expire: (state) => state.user.expire
    })
  },
  watch: {
    $route: {
      handler() {
        this.setActive()
      },
      deep: true
    },
    '$store.state.user.menu': {
      handler(res) {
        if (res && res.main) {
          this.items = JSON.parse(JSON.stringify(res.main || []))
          this.setActive()
        }
      },
      immediate: true,
      deep: true
    },
    mini: {
      handler(value) {
        localStorage.setItem('navbar.mini', value)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 打开菜单目录
    setActive() {
      const name = this.$route.name
      const module = this.$route.meta.module
      const parent = this.$route.meta.parent // 父级
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].code === module) {
          this.items[i].active = true
          if (this.items[i].children) {
            // 定位到所属的功能项
            const children = this.items[i].children
            for (let j = 0; j < children.length; j++) {
              if (children[j].code === name || children[j].code === parent) {
                this.selectedIndex = children[j].id
                return
              }
            }
          }
          return
        }
      }
    },
    btnClick(child) {
      if (child && child.type === 1) {
        this.selectedIndex = child.id
        this.$router.push({ name: child.code, params: {} })
      } else if (child && child.type === 2) {
        window.open(child.arg, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.gmenu {
  height: calc(-50px + 100vh);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid var(--v-dragback-base);
}

.menu {
  background-color: transparent !important;
  width: 100%;
  min-width: 160px;
}

.menu-header {
  overflow: hidden auto;
}

.menu-footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 14px 0px 14px 14px;
  cursor: pointer;
}

::v-deep .v-list-group__header__append-icon>i {
  font-size: 16px;
}

::v-deep .v-list-item__title {
  font-size: 14px;
}

::v-deep .v-navigation-drawer__border {
  background-color: transparent !important;
}

::v-deep .v-list-group__header {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

::v-deep .v-list-group__header.theme--dark {
  color: #fff;
}

::v-deep .v-list-group__header.theme--light {
  color: rgba(0, 0, 0, 0.87) !important;
}

::v-deep .theme--light .v-icon.v-icon {
  color: rgba(0, 0, 0, 0.54);
}

// 处理菜单缩进问题
::v-deep .v-list-group__header__prepend-icon {
  margin-right: 10px !important;
}

// 处理菜单缩进问题
::v-deep .v-list-group__items>.v-list-item {
  padding-left: 52px !important;
}

.xicon {
  min-width: 18px;
  min-height: 18px;
  max-width: 18px;
  max-height: 18px;
  margin-right: 8px;
}
</style>
