import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/auth' // get token from cookie
import DataNavBar from '@/views/DataNavBar.vue'
import store from '../store'

Vue.use(VueRouter)

// 处理路由报错问题，也可以将路径版本降到     "vue-router": "3.0.2"
// Redirected when going from "/login?redirect=%2Fdashboard" to "/dashboard" via a navigation guard
const orgPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return orgPush.call(this, location, onResolve, onReject)
  return orgPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/data/dashboard'
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      auth: false
    },
    component: () =>
      import('../views/Login.vue')
  },
  {
    path: '/public/info/terms-of-user',
    name: 'TermsOfUser',
    meta: {
      auth: false
    },
    component: () =>
      import('../views/TermsOfUser.vue')
  },
  {
    path: '/public/info/privacy-policy',
    name: 'PrivacyPolicy',
    meta: {
      auth: false
    },
    component: () =>
      import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/bigscreen',
    name: 'BigScreen',
    meta: {
      auth: true,
    },
    components: {
      default: () => import('../views/bigscreen/Index.vue')
    }
  },
  {
    path: '/data',
    components: {
      NavBar: () => import('../components/NavBarD.vue'),
      default: () => import('../views/Index.vue')
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        meta: {
          auth: true,
          module: 'monitor'
        },
        components: {
          DataNavBar,
          default: () => import('../views/Dashboard.vue')
        }
      },
      {
        path: 'product',
        name: 'Product',
        meta: {
          auth: true,
          module: 'product'
        },
        components: {
          DataNavBar,
          default: () => import('../views/Product.vue')
        }
      },
      {
        path: 'product/productDetail/:pkey',
        name: 'ProductDetail',
        meta: {
          auth: true,
          module: 'product'
        },
        components: {
          DataNavBar,
          default: () => import('../views/ProductDetail.vue')
        }
      },
      {
        path: 'product/productDetail/:pkey/draft',
        name: 'ProductDraft',
        meta: {
          auth: true,
          module: 'product'
        },
        components: {
          DataNavBar,
          default: () => import('../views/ProductDraft.vue')
        }
      },
      {
        path: 'department',
        name: 'Department',
        meta: {
          auth: true,
          module: 'device'
        },
        components: {
          DataNavBar,
          default: () => import('../views/Department.vue')
        }
      },
      {
        path: 'reapir',
        name: 'Repair',
        meta: {
          auth: true,
          module: 'device'
        },
        components: {
          DataNavBar,
          default: () => import('../views/Repair.vue')
        }
      },
      {
        path: 'customer',
        name: 'Customer',
        meta: {
          auth: true,
          module: 'device'
        },
        components: {
          DataNavBar,
          default: () => import('../views/Customer.vue')
        }
      },
      {
        path: 'alarmmain',
        name: 'AlarmMain',
        meta: {
          auth: true,
          module: 'device'
        },
        components: {
          DataNavBar,
          default: () => import('../views/AlarmMain.vue')
        }
      },
      {
        path: 'firmware',
        name: 'Firmware',
        meta: {
          auth: true,
          module: 'device'
        },
        components: {
          DataNavBar,
          default: () => import('../views/Firmware.vue')
        }
      },
      {
        path: 'firmware/:fid/detail',
        name: 'FirmwareDetail',
        meta: {
          auth: true,
          module: 'device'
        },
        components: {
          DataNavBar,
          default: () => import('../views/FirmwareDetail.vue')
        }
      },
      {
        path: 'otatask/:tid/detail',
        name: 'OtaTaskDetail',
        meta: {
          auth: true,
          module: 'device'
        },
        components: {
          DataNavBar,
          default: () => import('../views/OtaTaskDetail.vue')
        }
      },
      {
        path: 'firmware/:fid/upgrade',
        name: 'OtaBatchUpgrade',
        meta: {
          auth: true,
          module: 'device'
        },
        components: {
          DataNavBar,
          default: () => import('../views/OtaBatchUpgrade.vue')
        }
      },
      {
        path: 'customer/:userid/team',
        name: 'CustomerTeam',
        meta: {
          auth: true,
          module: 'device'
        },
        components: {
          DataNavBar,
          default: () => import('../views/CustomerTeam.vue')
        }
      },
      {
        path: 'devices',
        name: 'Devices',
        meta: {
          auth: true,
          module: 'device'
        },
        components: {
          DataNavBar,
          default: () => import('../views/Devices.vue')
        }
      },
      {
        path: 'devices/:pkey/:dkey',
        name: 'DevicesDashboard',
        meta: {
          auth: true,
          module: 'device'
        },
        components: {
          DataNavBar,
          default: () => import('../views/DevicesDashboard.vue')
        }
      },
      {
        path: 'user',
        name: 'User',
        meta: {
          auth: true,
          module: 'manage'
        },
        components: {
          DataNavBar,
          default: () => import('../views/User.vue')
        }
      },
      {
        path: 'role',
        name: 'Role',
        meta: {
          auth: true,
          module: 'manage'
        },
        components: {
          DataNavBar,
          default: () => import('../views/Role.vue')
        }
      },
      {
        path: 'classify',
        name: 'Classify',
        meta: {
          auth: true,
          module: 'manage'
        },
        components: {
          DataNavBar,
          default: () => import('../views/Classify.vue')
        }
      }
    ]
  } 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const whiteList = ['/login','/public/info/privacy-policy','/public/info/terms-of-user']

router.beforeEach(async (to, from, next) => {
  // set page title
  // document.title = getPageTitle(to.meta.title)
  // console.log('beforeEach ', from, ' --to--> ', to)

  const { token, pid } = getToken()
  // 在有令牌的情况下
  if (token) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      if (store.getters.id > 1) {
        if (pid > 0 && to.meta.auth) {
          // 没有权限，退出到登录页
          await store.dispatch('user/logout')
          next('/login')
        } else {
          next()
        }
      } else {
        try {
          await store.dispatch('user/getInfo')
          next({
            ...to,
            replace: true
          })
        } catch (error) {
          await store.dispatch('user/resetToken')
          next(`/login?redirect=${to.path}`)
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      const dev = new URL(window.location.href)
      if (dev) {
        if (dev.pathname) {
          const re = /[0-9]+/g
          const pids = re.exec(dev.pathname)
          if (pids) {
            const p = pids[0]
            const whtoken = dev.searchParams.get('whtoken')
            if (whtoken) {
              const pkg = { token: whtoken }
              await store.dispatch('user/share', pkg)
                .then(() => {
                  location.reload()
                })
              return
            }
          }
        }
      }
      next(`/login?redirect=${to.path}`)
    }
  }
})

export default router
