import ReconnectingWebSocket from 'reconnecting-websocket'
// import emitter from '@/utils/emitter'
// import { cameraEndpoint } from '@/api/camera'

// 不能直接改变 store 中的状态
// 改变 store 中的状态的唯一途径就是显式地提交 (commit) mutation
// 但可以直接读取
const state = {
  websocket: null,
  websocketState: 'disconnected'
}

// 用于修改state
// 每个 mutation 都有一个字符串的 事件类型 (type) 和 一个 回调函数 (handler)。
const mutations = {
  websocketCreate (state, websocket) {
    state.websocket = websocket
  },
  websocketFailure (state) {
    state.websocket = null
  },
  websocketOnOpen (state, rws) {
    state.websocketState = 'connected'
  },
  websocketOnClose (state, rws) {
    state.websocketState = 'disconnected'
  },
  websocketOnError (state, { rws, err }) {
    state.websocketState = 'disconnected'
  },
  websocketOnMessage (state, { rws, message }) {
    // emitter.emit('camera-message', message)
  },
  websocketSend (state, msg) {
    if (state.websocket && state.websocketState === 'connected') {
      state.websocket.send(JSON.stringify(msg))
    } else {
      console.error(' websocketSend Err:', state.websocketState)
    }
  },
  logout (state) {
    if (state.websocket) {
      state.websocket.close()
    }
    state.websocket = null
    state.websocketState = 'disconnected'
  }
}

const actions = {
  logout ({ commit }) {
    commit('logout')
  },
  // 连接云视频服务器
  // force = true 时强制刷新连接
  // async connect ({ dispatch, commit, state }, arg) {
  //   const { force, data } = arg
  //   if (state.websocketState === 'connected' && force === false) {
  //     if (data) {
  //       commit('websocketSend', data)
  //     }
  //     return
  //   }
  //   commit('logout')
  //   let inited = false
  //   const res = await cameraEndpoint({})
  //   const options = {
  //     reconnectInterval: 5000,
  //     connectionTimeout: 5000,
  //     maxRetries: 100
  //   }
  //   const uri = `${res.host}?key=${res.key}&time=${res.time}`
  //   const rws = new ReconnectingWebSocket(uri, [res.secret], options)
  //   rws.onopen = function () {
  //     commit('websocketOnOpen', rws)
  //     if (inited === false && data) {
  //       inited = true
  //       commit('websocketSend', data)
  //     }
  //   }
  //   rws.onclose = function (e) {
  //     commit('websocketOnClose', rws)
  //   }
  //   rws.onerror = function (err) {
  //     commit('websocketOnError', { rws, err })
  //     dispatch('alert/error', '云视频连接错误', { root: true })
  //   }
  //   rws.onmessage = json => {
  //     const message = JSON.parse(json.data)
  //     commit('websocketOnMessage', { rws, message })
  //   }
  //   commit('websocketCreate', rws)
  //   return new Promise((resolve) => {
  //     if (rws.readyState !== rws.OPEN) {
  //       rws.addEventListener('open', (_) => {
  //         resolve()
  //       })
  //     } else {
  //       resolve()
  //     }
  //   })
  // },
  // 在发送包的时候自动连接
  send ({ dispatch, commit, state }, data) {
    if (state.websocketState === 'connected') {
      commit('websocketSend', data)
    } else {
      dispatch('connect', { force: false, data: data })
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
