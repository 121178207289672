import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';

Vue.config.productionTip = false
Vue.use(Viewer);

new Vue({
  router,
  store,
  vuetify,
  mounted () {
    const theme = localStorage.getItem('theme.dark') || 'false'
    if (theme) {
      if (theme === 'true') {
        this.$vuetify.theme.dark = true
      } else {
        this.$vuetify.theme.dark = false
      }
    } else if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      this.$vuetify.theme.dark = true
    }
    localStorage.setItem('theme.dark', this.$vuetify.theme.dark.toString())
  },
  render: h => h(App)
}).$mount('#app')
