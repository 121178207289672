<template>
  <v-app id="whapp">
    <router-view name="NavBar"></router-view>
    <v-main :class="{ whhidden: ispreview }">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  mounted() {
  },
  computed: {
    ispreview() {
      return this.$route.name === 'Preview'
    }
  },
  data: () => ({
    //
  })
}
</script>

<style lang="scss">
html {
  overflow-y: auto !important;
  font-size: 14px;
}

.v-application {
  font-size: 14px;
}

// 预览时会出现滚动条，隐藏
.whhidden {
  overflow: hidden;
}

@font-face {
  font-family: "AlibabaPuhui";
  font-display: fallback;
  src: url("./assets/fonts/AlibabaPuHui/Regular.woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AlibabaPuhui";
  font-display: fallback;
  src: url("./assets/fonts/AlibabaPuHui/Bold.woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SourceHanSerif";
  font-display: fallback;
  src: url("./assets/fonts/SourceHanSerif/Regular.woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SourceHanSerif";
  font-display: fallback;
  src: url("./assets/fonts/SourceHanSerif/Bold.woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SourceHanSansSC";
  font-display: fallback;
  src: url("./assets/fonts/SourceHanSansSC/Regular.woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SourceHanSansSC";
  font-display: fallback;
  src: url("./assets/fonts/SourceHanSansSC/Bold.woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "lcdbq";
  font-display: fallback;
  src: url("./assets/fonts/Lcdbq/LCDBQ-Italic.woff");
  font-weight: normal;
  font-style: normal;
}

.v-snack:not(.v-snack--absolute) {
  z-index: 99999 !important;
  padding-top: 25px !important;
}
</style>
